const shirtData = [
    {
        id: 1,
        type: 1,
        color: '#CCC',
        image: ''
    },
    {
        id: 2,
        type: 1,
        color: '#7349af',
        image: ''
    },
    {
        id: 3,
        type: 1,
        color: '#2a248b',
        image: ''
    },
    {
        id: 4,
        type: 1,
        color: '#af4999',
        image: ''
    },
    {
        id: 5,
        type: 1,
        color: '#337863',
        image: ''
    },
    {
        id: 6,
        type: 2,
        color: '#c7dc98',
        image: ''
    },
    {
        id: 7,
        type: 2,
        color: '#555555',
        image: ''
    },
    {
        id: 8,
        type: 2,
        color: '#086593',
        image: ''
    },
    {
        id: 9,
        type: 2,
        color: '#efe864',
        image: ''
    }
]

export { shirtData };