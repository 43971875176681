<template>
  <div class="home">
    <div class="jumbotron">
      <h1>vote for up to 5 favorite colors from each collection.</h1>
    </div>
    <div class="collection-logo origin-logo"></div>
    <BoxListVoter 
        name="origin" 
        :data="shirts.filter((x) => x.type == 1)"
        :votes="originVotes"
        :maxVotes="maxVotes"
    ></BoxListVoter>
    <div class="collection-logo luxe-logo"></div>
    <BoxListVoter 
        name="luxe"
        :data="shirts.filter((x) => x.type == 2)"
        :votes="luxeVotes"
        :maxVotes="maxVotes"
    ></BoxListVoter>
  </div>
</template>

<script>
  import BoxListVoter from '@/components/BoxListVoter.vue';
  import { shirtData } from '../assets/data.js';

  const maxVotes = 5;

  let originVotes = [], luxeVotes = [], votes = [];

  export default {
    data() {
      return {
        shirts: shirtData,
        originVotes: originVotes,
        luxeVotes: luxeVotes,
        votes: votes,
        maxVotes: maxVotes
      };
    },
    components: {
      BoxListVoter
    }
  }
</script>

<style lang="scss">
  .jumbotron {
    padding: 100px 30px;
    background: rgb(218,227,230);
    background: linear-gradient(20deg, rgba(218,227,230,1) 0%, rgba(79,78,101,1) 100%);
    color: white;

    h1 {
      font-weight: 400;
    }
  }

  .collection-logo {
    margin: 0 auto;
    margin-top: 30px;
    height: 100px;
    max-width: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .origin-logo {
    background-image: url('../assets/origin.png');
  }

  .luxe-logo {
    background-image: url('../assets/luxe.png');
  }

</style>