<template>
  <!--nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav-->
  <nav id="header">
    <div id="header-logo"></div>
  </nav>
  <router-view />
  <footer>
    © 2024 Sahil Bawa, All Rights Reserved.
  </footer>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#header {
  display: block;
  padding: 10px;
  height: 50px;
  background-color: rgba(255, 255, 255, .8);
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .3);

  #header-logo {
    height: 100%;
    width: 100%;
    background-image: url("./assets/logo-black5.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

footer {
  background-color: #ccc;
  color: #444;
  padding: 15px;
}
</style>
