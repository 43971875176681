<template>
  <section>
    <div class="votes-sticky">
      <div class="votes-container">
        <span
            v-for="(n, index) in 5"
            class="vote-circle"
            :class="{active: votes[index] !== undefined}"
            :key="index"
        ></span>
      </div>
    </div>
    <div class="box-container">
      <div  class="box"
            v-for="item in data"
            :key="item.id"
            @click="toggleBox(item.id)"
            :class="{selected: votes.includes(item.id)}"
            :style="{ background: `linear-gradient(150deg, rgba(229,235,237,1) 0%, ${item.color} 100%)` }"
      >
      </div>
    </div>
    <div class="spacing-45"></div>
  </section>
</template>

<script>
let toggleBox = function(id) {
  var index = this.votes.indexOf(id);
  if (index > -1) {
    this.votes.splice(index, 1);
    this.currNumVotes--;
  } else {
    this.votes.push(id);
    this.currNumVotes++;
  }
}

export default {
  name: "BoxListVoter",
  data() {
    return {
      currNumVotes: 0
    };
  },
  props: {
    name: String,
    data: Array,
    votes: Array
  },
  methods: {
    toggleBox: toggleBox
  },
  created() {
    this.currNumVotes = 0;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.votes-sticky {
  position: sticky;
  top: 80px;

  .votes-container {
    display: inline-flex;
    background-color: rgba(255, 255, 255, .8);
    padding: 10px;
    border-radius: 100px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .1);
  }
}
.vote-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, .1);
  margin: 0 5px;

  &.active {
    background-color: #666;
  }
}
.box-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .box {
    margin: 10px;
    height: 400px;
    width: 300px;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      border: 5px solid #ccc;
    }

    &.selected {
      border: 5px solid gold;
    }
  }
}
</style>
